/**
 * Flexible Blog Previews Section
 */

@import "assets/scss/utilities/mixins";
@import "assets/scss/utilities/variables";
@import "../../../node_modules/foundation-sites/scss/util/_unit.scss";
@import "../../../node_modules/foundation-sites/scss/util/_breakpoint.scss";

$breakpoints: (
    small: 0,
    medium: 640px,
    large: 1024px,
    xlarge: 1200px,
    xxlarge: 1440px,
);

.blog-previews {
    &.padding-default {
        @include breakpoint( medium ) {
            padding-top: rem-calc(80);
            padding-bottom: rem-calc(8);
        }
    } 

    &.small-padding-default {
        @include breakpoint( small only ) {
            padding-top: rem-calc(40);
            padding-bottom: rem-calc(40);
        }
    }   

    &.margin-default {
        @include breakpoint( medium ) {
            padding-top: rem-calc(80);
            padding-bottom: rem-calc(8);
        }
    } 

    &.small-margin-default {
        @include breakpoint( small only ) {
            padding-top: rem-calc(40);
            padding-bottom: rem-calc(40);
        }
    }

    &__container {
       @include breakpoint( small only ) {
           margin-left: 0 !important;
           margin-right: 0 !important;
           padding-left: 0 !important;   
           padding-right: 0 !important;   
       }
    }

    &__post-row {
        
        @include breakpoint( small only ) {
            margin-left: 0 !important;
            margin-right: 0 !important;

            
            display: grid;
            justify-content: start !important;
            grid-template-columns: 5vw repeat( 3, 282px );
            overflow: scroll auto;
            padding: 0;
            gap: 20px;

            &::before {
                content: '';
                display: block;
                height: 100%;
            }
        }

    }

    &__post-column[class] {
        @include breakpoint( small only ) {
            padding: 0;
        }

        @include breakpoint( large down ) {
            // display: none;
            
            &:nth-child(1),
            &:nth-child(2) {
                display: block;
            }

            @include breakpoint( landscape ) {
                //display: none;

                &:nth-child(1),
                &:nth-child(2) {
                    display: block;
                }
            }
        }

    }

}

.blog-preview {
    $post-image-height: 240px;
    $post-image-height--mobile: 178px;
    height: 100%;
    background: $true-white;
    // box-shadow: 0 0 20px 0 rgba(0,0,0,0.13); 
    
    @include breakpoint( small only ) {
        // padding-left: 15px;   
        // padding-right: 15px;  

        &:first-child {
            // padding-left: 0;
        } 
    }

    &__container {
        height: 100%;
    }

    &__body {
        height: calc( 100% - #{$post-image-height} );
        padding: rem-calc(20 0);
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center;

        @include breakpoint( medium ) {
            padding: rem-calc(24 40);
        }
    }

    &__image {
        height: rem-calc($post-image-height);
        box-shadow: 0 16px 32px rgba(#000000, 0.5);

        @include breakpoint( small only ) {
           height: rem-calc($post-image-height--mobile);
        }
        
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__category {
        font-family: $merri;
        font-size: rem-calc(13px);
        color: #767676;
        letter-spacing: 0;
        text-align: center;
        line-height: rem-calc(25px);
        margin-right: rem-calc(12);
        margin-left: rem-calc(12);
    }

    &__date {
        font-family: $merri;
        font-size: rem-calc(13px);
        color: #767676;
        letter-spacing: 0;
        text-align: center;
        line-height: rem-calc(25px);
        margin-right: rem-calc(12);
        margin-left: rem-calc(12);
    }

    &__title,
    &__title > a {
        font-family: $merri;
        font-size: rem-calc(25px);
        color: get-color(primary);
        line-height: 1.2;
        letter-spacing: 0;
        text-align: center;
    }

    &__button {
        margin-top: rem-calc($grid-gutter/1.5);
        margin-bottom: rem-calc($grid-gutter/2);
    }
}