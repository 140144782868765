/**
 * Theme Variables
 */

@import 'https://fonts.googleapis.com/css2?family=Merriweather&display=swap';

// Herbal One 
$gothic: itc-avant-garde-gothic-pro, sans-serif !global; // Adobe Fonts. Ask Emma for login if needed. 
$merri: 'Merriweather', serif !global;

$herbal-primary: #694D87 !global;
$herbal-primary-alt: #9F75CC !global;
$herbal-secondary: #FD02FE !global;
$herbal-tertiary: #2A9DF8 !global;
$herbal-gradient: linear-gradient(45deg, $herbal-primary-alt 0%, $herbal-secondary 100%) !global;

$herbal-shadow-4: 0 16px 24px 0 rgba(253,2,254,0.20);
$herbal-shadow-5: 0 16px 48px rgba($herbal-secondary, 0.5);

// Bring Vars out of Foundation Settings
$true-black: #000000;
$black: #333;
$true-white: #ffffff;
$global-weight-light: 300;
$global-weight-regular: 400;
$grid-gutter: 15px !default;

// Theme Fonts
$mont: $merri !global; // Google Fonts
$fa: Font Awesome\ 5 Pro !global;
$far: Font Awesome\ 5 Regular !global;
$fas: Font Awesome\ 5 Solid !global;
$fal: Font Awesome\ 5 Light !global;
$fab: Font Awesome\ 5 Brands!global;

// Weight
$weight-extralight: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-black: 700;
$global-weight-light: 300;
$global-weight-regular: 400;
$global-weight-medium: 500;
$global-weight-semibold: 600;
$global-weight-bold: 700;